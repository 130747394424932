import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Tabla } from "./Tabla";
import { Seo } from "../Layout/Seo";
import GatsbyImages from "../GatsbyImages";
const PostPage = ({ pageContext, location, lang }) => {
  const { blog, resultfooterpost } = pageContext;

  const url = location.href;
  const [updatedText, setUpdatedText] = useState("");
  const [listText, setListText] = useState("");
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    document.getElementById("header").classList.add("stick_header");
  }, []);

  useEffect(() => {
    if (!blog.text) return;
    const addNoFollowsToExternal = (html) => {
      const internalLinkRegex = /https?:\/\/(?:www\.)?sendity\.com/i;
      const anchorRegex =
        /(<a\s+(?!.*\brel=)[^>]*)(href="https?:\/\/[^"]+")([^>]*)(?:\/?>)/gi;
      return html.replace(anchorRegex, function (match, p1, p2, p3) {
        if (!internalLinkRegex.test(p2)) {
          return p1 + p2 + p3 + ' rel="nofollow">';
        } else {
          return match;
        }
      });
    };
    const getDoc = (html) => {
      let newText = html;
      let i = 0;
      let array = [];
      let doc = new DOMParser().parseFromString(newText, "text/html");
      const list = doc.querySelectorAll("h2, h3, h4");
      list.forEach((e, index) => {
        if (e.innerText.length > 1) {
          e.setAttribute("id", `${e.localName}_${index}`);
        } else {
          array[i] = index;
          i++;
        }
      });
      const listcopu = Object.keys(list).map((key) => [list[key]]);
      for (let i = 0; i < array.length; i++) {
        listcopu.splice(array[i] - i, 1);
      }
      doc = new XMLSerializer().serializeToString(doc);
      setListText(listcopu);
      setUpdatedText(doc);
    };

    getDoc(addNoFollowsToExternal(blog.text));
  }, [blog.text]);
  const footerpost = resultfooterpost.filter(
    ({ locale }) => locale === lang,
  )[0];

  const goBackLink = lang === "en" ? "/en/blog/" : "/blog/";
  return (
    <Seo
      title={pageContext.blog.seoTitle}
      description={pageContext.blog.seoDescription}
    >
      <section className="section-block p-4 mt-4 article-body" id="post">
        <div className="container">
          <GatsbyImages
            src={blog.image.localFile.childImageSharp.gatsbyImageData}
            className="content-image-unic"
            ext={blog.image.localFile.ext}
            alt={blog.image.alternativeText}
          />
          <div className="post-header">
            <Link to={goBackLink} className="volver">
              <span></span>Volver
            </Link>
            <h1>{blog.title}</h1>
            <ul className="headpost">
              <li>
                {blog.category.Title} · {blog.date}
              </li>
              <li>
                {blog.reading_time}‘<span></span>
              </li>
            </ul>
          </div>
          <div className="postdetail">
            <div className="indexing">
              <div className="indexlist">
                <div className="indextext">Índice</div>
                <Tabla content={listText} />
              </div>
            </div>
            <div className="post-item">
              <div className="col-12 ">
                <div
                  className="color: #939393;"
                  dangerouslySetInnerHTML={{
                    __html: updatedText,
                  }}
                ></div>
                <div className="advertisment">{footerpost.Text}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cta">
        <div className="container">
          <div className="avail-in d-flex flex-wrap">
            <div className="service-in col-lg-7 col-md-12">
              <h4>Envía tu dinero al precio real del mercado con Sendity</h4>
            </div>
            <div className="whatsapp col-lg-5 col-md-12">
              <Link to="/enviar-dinero">¡Me apunto!</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="cards-block">
        <div className="container">
          <div className="cards-in">
            <h3>Seguir leyendo</h3>
            <div className="cards">
              <ul className="clearfix autoplay d-flex flex-wrap">
                {blog.blogsBelongTo.map(
                  ({ text, path, thumbnail, date, title }, index) => {
                    const match = /<p[^>]*>(.*?)<\/p>/i.exec(text);
                    const firstPContent = match ? match[0] : "";
                    return (
                      <li className="" key={index}>
                        <Link to={`/blog/${path}`}>
                          <div className="cardWrapper">
                            <div className="card1">
                              <GatsbyImages
                                src={
                                  thumbnail.localFile.childImageSharp
                                    .gatsbyImageData
                                }
                                ext={thumbnail.localFile.ext}
                                className="content-image"
                                alt={thumbnail.alternativeText}
                              />
                              <div className="category">
                                <span>{blog.category.Title}</span>
                              </div>
                            </div>
                            <div className="card-content">
                              <span>{date}</span>
                              <h5>{title}</h5>
                              <div
                                className="card-text"
                                dangerouslySetInnerHTML={{
                                  __html: firstPContent,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  },
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Seo>
  );
};

export default PostPage;
